import React from 'react'
import { graphql } from 'gatsby'

// Elements
import FlexContentHandler from 'components/shared/FlexContentHandler'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'


const PageTemplate = ({
  data: {
    page: { wordpress_id: wpId, path, yoast_meta: yoast, acf },
  },
  location,
  pageContext
}) => {
  let sections = acf.content_page || []

  if(acf && acf.content) {
    sections = []

    acf.content.forEach(section => {
      const sectionData = section
  
      if(sectionData.acf_fc_layout && !sectionData.internal) {
        sectionData.internal = { type: `WordPressAcf_${sectionData.acf_fc_layout}` }
      }
  
      sections.push(sectionData)
    })
  }

  return (
    <Layout location={location}>
      <SEO yoast={yoast} path={path} />

      <FlexContentHandler wpId={wpId} pageContext={pageContext} location={location} fields={sections} />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...generalPageFragment
    }
  }
`

export default PageTemplate
